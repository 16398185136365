<div class="container-fluid imprint-container"
     id="imprint-container">
  <div id="content"
       class="row">
    <div class="col-2"></div>
    <div class="col-8 mt-5">
      <h1 style="font-weight: bold">Impressum</h1>
      <p>
        FraGround Fraport Ground Handling Professionals GmbH<br />
        Cargo City Nord, Gebäude 458<br />
        60549 Frankfurt am Main – Flughafen<br />
        Telefon: +49 69 690 70652<br />
        Telefax: +49 69 690 59684<br />
        E-Mail: Duesseldorf&#64;arbeitsagentur.de<br />
        Website: www.FraGround.de<br />
        <br />
        Sitz der Gesellschaft<br />
        Frankfurt / Main – Flughafen<br />
        Amtsgericht Frankfurt / Main<br />
        HRB 58100<br />
        Umsatzsteuer-Identifikationsnummer: DE 232886102<br />
        <br />
        <br />
        Geschäftsführung<br />
        Dr. Markus Kleiner, Carsten Röcken<br />
        Vorsitzende des Aufsichtsrates: Julia Kranenberg<br />
        <br />
        <br />
        Zuständige Aufsichtsbehörde / Erlaubnisbehörde<br />
        Bundesagentur für Arbeit - Agentur für Arbeit Düsseldorf<br />
        Besucheranschrift:<br />
        Grafenberger Allee 300<br />
        40237 Düsseldorf<br />
        <br />
        <br />
        Postanschrift:<br />
        Agentur für Arbeit Düsseldorf<br />
        40180 Düsseldorf<br />
        Tel: +49 211 692 1100<br />
        Fax: +49 211 692-1610<br />
        E-Mail: Duesseldorf&#64;arbeitsagentur.de<br />
      </p>
    </div>
  </div>
</div>